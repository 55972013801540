@media (min-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: 100%;
    height: auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: auto;
    height: 100%;
  }
}

.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
}
